<template>
		<pui-datatable
			:modelName="modelName"
		></pui-datatable>
</template>

<script>

export default {
	data() {
		return {
			modelName: 'operationsprofilecompany',
		}
	}
}
</script>
